<template>
  <div
    class="relative"
    style="background: linear-gradient(to bottom, #fff 20rem, #F3F6F5 0);"
  >
    <AppHeader />

    <slot />

    <AppFooter />
  </div>
</template>
